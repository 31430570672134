package ru.mrKurilin.mrKurilinWebSite.keyframes

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.css.percent

val FadeIn by Keyframes {
    from { Modifier.opacity(0.percent) }
    to { Modifier.opacity(100.percent) }
}
