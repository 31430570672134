package ru.mrKurilin.mrKurilinWebSite.components.homePage

import ru.mrKurilin.mrKurilinWebSite.components.homePage.model.Contact
import ru.mrKurilin.mrKurilinWebSite.util.Res

object Contacts {

    fun getContactsList(): List<Contact> {
        return listOf(
            Contact(
                path = "https://www.codewars.com/users/mrKurilin",
                icon = Res.Icon.codewars
            ),
            Contact(
                path = "https://github.com/mrKurilin",
                icon = Res.Icon.github
            ),
            Contact(
                path = "mailto:kurilin.anton.94@gmail.com",
                icon = Res.Icon.gmail
            ),
            Contact(
                path = "https://leetcode.com/mrKurilin/",
                icon = Res.Icon.leetcode
            ),
            Contact(
                path = "https://www.linkedin.com/in/mrkurilin/",
                icon = Res.Icon.linkedin
            ),
            Contact(
                path = "https://t.me/mrKurilin",
                icon = Res.Icon.telegram
            ),
        )
    }
}