package ru.mrKurilin.mrKurilinWebSite.keyframes

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.translateX
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.css.percent

val Bounce by Keyframes {
    from { Modifier.translateX((0).percent) }
    to { Modifier.translateX((-100).percent) }
}