package ru.mrKurilin.mrKurilinWebSite.components.homePage

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.BackgroundRepeat
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.functions.CSSImage
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import ru.mrKurilin.mrKurilinWebSite.keyframes.Bounce

@Composable
fun HomePageBackground(modifier: Modifier = Modifier) {
    Box(
        modifier = modifier
            .backgroundImage(
                CSSImage.Companion.of(url("bg.jpg"))
            )
            .fillMaxHeight()
            .width(15000.px)
            .backgroundRepeat(BackgroundRepeat.RepeatX)
            .position(Position.Fixed)
            .backgroundSize(BackgroundSize.Contain)
            .animation(
                Bounce.toAnimation(
                    duration = 600.s,
                    iterationCount = AnimationIterationCount.Infinite,
                    timingFunction = AnimationTimingFunction.Linear,
                )
            )
    )
}