package ru.mrKurilin.mrKurilinWebSite.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import ru.mrKurilin.mrKurilinWebSite.components.homePage.HomePageBackground
import ru.mrKurilin.mrKurilinWebSite.components.homePage.SocialLinkList
import ru.mrKurilin.mrKurilinWebSite.keyframes.FadeIn
import ru.mrKurilin.mrKurilinWebSite.util.Res

@Page
@Composable
fun HomePage() {
    val breakpoint = rememberBreakpoint()

    val fadeInAnimation = FadeIn.toAnimation(
        duration = 3.s
    )

    HomePageBackground()

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .fillMaxSize(),
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .scrollBehavior(ScrollBehavior.Smooth)
                .position(Position.Relative)
        ) {
            Image(
                src = Res.Image.mrKurilin,
                modifier = Modifier
                    .clip(Circle())
                    .size(if (breakpoint >= Breakpoint.MD) 300.px else 150.px)
                    .animation(fadeInAnimation)
            )
            SpanText(
                text = "Anton Kurilin",
                modifier = Modifier
                    .fontSize(if (breakpoint >= Breakpoint.MD) 100.px else 32.px)
                    .animation(fadeInAnimation)
            )
            SpanText(
                text = "Android developer",
                modifier = Modifier
                    .fontSize(if (breakpoint >= Breakpoint.MD) 50.px else 22.px)
                    .animation(fadeInAnimation)
            )
            SocialLinkList()
        }
    }
}