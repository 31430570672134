package ru.mrKurilin.mrKurilinWebSite.keyframes

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val RaiseFadeIn by Keyframes {
    from { Modifier.opacity(0.percent).padding(top = 150.px) }
    to { Modifier.opacity(100.percent).padding(top = 0.px) }
}