package ru.mrKurilin.mrKurilinWebSite.components.homePage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.AnimationPlayState
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import ru.mrKurilin.mrKurilinWebSite.keyframes.RaiseFadeIn

@Composable
fun SocialLink(
    path: String,
    icon: String,
    state: MutableState<Boolean>,
    modifier: Modifier = Modifier
) {
    Link(
        path = path,
        modifier = modifier
            .then(IconStyle.toModifier())
            .color(Color.black)
            .margin(16.px)
            .size(48.px)
            .animation(
                RaiseFadeIn.toAnimation(
                    duration = 1.s,
                    playState = if (state.value) AnimationPlayState.Running else AnimationPlayState.Paused
                )
            )
    ) {
        Image(
            src = icon,
            modifier = Modifier.color(Color.black).size(48.px)
        )
    }
}