package ru.mrKurilin.mrKurilinWebSite.util

object Constants {
    const val WEBSITE = "https://stevdza-san.com/"
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"

    const val LOREM_IPSUM_LONG =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    const val LOREM_IPSUM_SHORTEST =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    const val LOREM_IPSUM_SHORT =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
}

object Res {
    object Icon {
        const val codewars = "codewars.png"
        const val github = "github.png"
        const val gmail = "gmail.png"
        const val leetcode = "leetcode.png"
        const val linkedin = "linkedin.png"
        const val telegram = "telegram.png"
    }

    object Image {
        const val northShine = "north_shine.png"
        const val mrKurilin = "mrKurilin.jpg"
    }
}