package ru.mrKurilin.mrKurilinWebSite.components.homePage

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.px

@Composable
fun SocialLinkList() {
    val states = mutableListOf<MutableState<Boolean>>()
    val contacts = Contacts.getContactsList()
    val breakpoint = rememberBreakpoint()

    SimpleGrid(
        modifier = Modifier.height(
            if (breakpoint >= Breakpoint.MD) 96.px else 160.px
        ),
        numColumns = numColumns(
            base = contacts.size / 2,
            sm = contacts.size / 2,
            md = contacts.size
        )
    ) {
        contacts.forEach { contact ->
            val state = remember { mutableStateOf(false) }
            states.add(state)
            SocialLink(
                path = contact.path,
                icon = contact.icon,
                state = state
            )
        }
    }

    LaunchedEffect(Unit) {
        states.forEach { state ->
            state.value = true
            delay(100)
        }
    }
}